import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  useEffect(() => {
    let sc: HTMLElement | null, divNode: HTMLElement | null;
    try {
      sc = document.createElement('script');
      sc.src = 'https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/017c1eea70e96445ec5f697ab68c45c50fbaca217542b7b76cef0a6b983f0fa0/form.js';
      sc.crossOrigin = 'anonymous';
      sc.id = 'fs_017c1eea70e96445ec5f697ab68c45c50fbaca217542b7b76cef0a6b983f0fa0';
      divNode = document.getElementById("contact-form");
      divNode?.appendChild(sc);

      new MutationObserver(function(mutationsList, observer) {
        mutationsList.forEach(mutation => {
          if (mutation.addedNodes) {
            mutation.addedNodes.forEach(node => {
              var css = node.querySelector ? node.querySelector('link[href*="webforms.css"]') : null;
              if (css) {
                observer.disconnect();
                css.addEventListener('load', function() {
                  setTimeout(function() {
                    loop: for (var i = 0; i < document.styleSheets.length; i++) {
                      var styleSheet = document.styleSheets[i];
                      if (styleSheet.href && styleSheet.href.includes('webforms.css')) {
                        var rules = styleSheet.rules;
                        for (var j = 0; j < rules.length; j++) {
                          var rule = rules[j];
                          if (rule.selectorText && rule.selectorText.includes('.hidden,')) {
                            var cssText = rule.cssText.replace('.hidden,', '.fs-webform-container .hidden,');
                            styleSheet.deleteRule(j);
                            styleSheet.insertRule(cssText, j);
                            break loop;
                          }
                        }
                      }
                    }
                  });
                });
              }
            });
          }
        });
      }).observe(document.body, { childList:true, subtree: true });      
    } catch (e) {}
    return () => {
      sc && divNode && divNode.removeChild(sc);
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Contact us | README Digital</title>
      </Helmet>
      <section className="h-[280px] bg-[url('../images/contact-page/banner.png')] bg-center bg-cover flex items-center">
        <div className="container">
          <h1 className="text-[52px] font-bold uppercase">Get in Touch</h1>
        </div>
      </section>
      <section className="bg-[#F5F5F5]">
        <div id="contact-form">
        </div>
      </section>
    </Fragment>
  );
};

export default ContactPage;
